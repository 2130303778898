// project import
import Error404 from 'pages/undefined-pages/404';
import Error403 from 'pages/undefined-pages/403';
import Error500 from 'pages/undefined-pages/500';

// ==============================|| APPS ROUTING ||============================== //
const ErrorRoutes = {
  path: '/',
  children: [
    {
      path: '404',
      element: <Error404 />
    },
    {
      path: '403',
      element: <Error403 />
    },
    {
      path: '500',
      element: <Error500 />
    }
  ]
};

export default ErrorRoutes;
