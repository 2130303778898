// assets
import { ToolOutlined, UnorderedListOutlined, SettingOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
  ToolOutlined,
  UnorderedListOutlined,
  SettingOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const config = {
  id: 'config_parent',
  title: 'CONFIG',
  type: 'group',
  children: [
    {
      id: 'services',
      title: <FormattedMessage id="menu-items.services" />,
      url: '/config/services',
      icon: icons.SettingOutlined,
      type: 'item'
    },
    {
      id: 'users',
      title: <FormattedMessage id="menu-items.users" />,
      url: '/config/users',
      icon: icons.SettingOutlined,
      type: 'item'
    }
  ]
};

export default config;
