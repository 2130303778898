import { createSlice } from '@reduxjs/toolkit';

// initial state
export const initialState = {
  scriptsConfig: null,
  toolsConfig: null
};

// ==============================|| SLICE - APPLICATIONS ||============================== //

const applications = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setScriptsData(state, action) {
      state.scriptsConfig = action.payload;
    },
    setToolsData(state, action) {
      state.toolsConfig = action.payload;
    }
  }
});

export default applications.reducer;

export const { setScriptsData, setToolsData } = applications.actions;
