import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// project import
import Loadable from 'components/Loadable';
import { ContactsContextProvider as ContactsProvider } from 'contexts/ContactsContext';

const CompaniesTable = Loadable(lazy(() => import('pages/contacts/companiesTable')));
const ContractCarsTable = Loadable(lazy(() => import('pages/cars/contractsCarTable')));
const DemandeCarsTable = Loadable(lazy(() => import('pages/cars/demandeCarsTable')));
const RequestCarsTable = Loadable(lazy(() => import('pages/cars/requestCarsTable')));
const InventoryCarsTable = Loadable(lazy(() => import('pages/cars/inventoryCarsTable')));
const PreparationCarsTable = Loadable(lazy(() => import('pages/cars/preparationCarsTable')));
const LitigeCarsTable = Loadable(lazy(() => import('pages/cars/litigeCarsTable')));
const CarSourcingContactsTable = Loadable(lazy(() => import('pages/contacts/carSourcingContactsTable')));
const CompanyProfile = Loadable(lazy(() => import('pages/contacts/company')));
const CarProfile = Loadable(lazy(() => import('pages/cars/carProfile')));
const TabCar = Loadable(lazy(() => import('sections/apps/profiles/car/TabCar')));
const TabCompany = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompany')));
const TabCompanyAnalytics = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompanyAnalytics')));
const TabCompanyContacts = Loadable(lazy(() => import('sections/apps/profiles/company/TabCompanyContacts')));

// ==============================|| APPS ROUTING ||============================== //

const ContactsRoutes = {
  path: '/',
  element: (
    <ContactsProvider>
      <Outlet />
    </ContactsProvider>
  ),
  children: [
    {
      path: '/contracts',
      element: <ContractCarsTable />
    },
    {
      path: '/demandes',
      element: <DemandeCarsTable />
    },
    {
      path: '/demandes/:id',
      element: <CarProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCar />
        },
        {
          path: 'contacts',
          element: <TabCompanyContacts />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/commandes',
      element: <RequestCarsTable />
    },
    {
      path: '/inventaire',
      element: <InventoryCarsTable />
    },
    {
      path: '/clients',
      element: <CompaniesTable />
    },
    {
      path: '/client/:id',
      element: <CompanyProfile />,
      children: [
        {
          path: '',
          element: <Navigate to="details" />
        },
        {
          path: 'details',
          element: <TabCompany />
        },
        {
          path: 'contacts',
          element: <TabCompanyContacts />
        },
        {
          path: 'analytics',
          element: <TabCompanyAnalytics />
        }
      ]
    },
    {
      path: '/planning',
      element: <CarSourcingContactsTable />
    },
    {
      path: '/preparation',
      element: <PreparationCarsTable />
    },
    {
      path: '/finance',
      element: <CarSourcingContactsTable />
    },
    {
      path: '/kpi',
      element: <CarSourcingContactsTable />
    },
    {
      path: '/litige',
      element: <LitigeCarsTable />
    }
  ]
};

export default ContactsRoutes;
