import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import LoadingButton from '@mui/lab/LoadingButton';

const ConfirmationDialog = ({ title, content, handleConfirm, handleClose, openDialog, loading = false }) => {
  return (
    <Dialog open={openDialog} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <Box sx={{ p: 1, py: 1.5 }}>
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose}>
            <FormattedMessage id="confirmation-dialog.cancel" />
          </Button>
          <LoadingButton variant="contained" onClick={handleConfirm} loading={loading} autoFocus>
            <FormattedMessage id="confirmation-dialog.confirm" />
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleClose: PropTypes.func,
  loading: PropTypes.bool,
  openDialog: PropTypes.bool
};

export default ConfirmationDialog;
