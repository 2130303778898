import { apiGet, apiPost, apiDelete, apiPut } from './api';

export const getContacts = (filters) => {
  const config = {
    responseType: 'json'
  };

  const query = filters
    ? encodeURI(
        Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      )
    : '';

  return apiGet(`/v1/trustlog-park/contacts?${query}`, config);
};

export const getContactById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/contacts/${id}`, config);
};

export const postContacts = (data) => {
  data.forEach((obj) => {
    obj['languages'] = typeof obj.languages === 'string' ? obj.languages.split(',') : Array.isArray(obj.languages) ? obj.languages : [];
    obj['mobile'] = obj.mobile.toString() || '';
    obj['phone'] = obj.phone.toString() || '';
  });
  const config = {
    responseType: 'json'
  };
  return apiPost(`/v1/trustlog-park/contacts/`, data, config);
};

export const sendFile = async (id, fileName, file, typeFile) => {
  const url = `/v1/trustlog-park/contacts/${id}/sendFile`;
  const metadata = JSON.stringify({ fileName, typeFile });
  const blobMetadata = new Blob([metadata], {
    type: 'application/json'
  });

  const formData = new FormData();

  formData.append('metadata', blobMetadata, 'metadata');
  formData.append('file', file);
  const config = {
    responseType: 'json'
  };

  return apiPost(url, formData, config);
};

export const getContactFile = async (id, typeFile) => {
  const url = `/v1/trustlog-park/contacts/${id}/getFile?typeFile=${typeFile}`;
  const config = {
    responseType: 'arraybuffer'
  };
  return apiGet(url, config);
};

export const deleteContactById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiDelete(`/v1/trustlog-park/contacts/${id}`, config);
};

export const getContactsByCompanyId = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/companies/${id}/contacts`, config);
};

export const putContactById = (id, data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/contact/${id}`, data, config);
};

export const sendVerificationEmailById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/contact/sendVerificationMail/${id}`, config);
};

export const sendValidationContactById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/contact/sendValidationMail/${id}`, config);
};

export const getUserFirebaseInfo = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/contact/firebaseInfo/${id}`, config);
};
