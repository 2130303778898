import { useContext } from 'react';

// auth provider
import UtilsContext from 'contexts/UtilsContext';

// ==============================|| AUTH HOOKS ||============================== //

const useUtils = () => {
  const context = useContext(UtilsContext);

  if (!context) throw new Error('UtilsContext must be use inside provider');

  return context;
};

export default useUtils;
