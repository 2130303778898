import { useState } from 'react';

// material-ui
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';

// third-party
import { useIntl } from 'react-intl';

// assets
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';
import ConfirmationDialog from 'components/dialogs/generic/ConfirmationDialog';
import { resetPassword } from 'api/UserService';
import { QuestionCircleOutlined, RedoOutlined } from '@ant-design/icons';

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

const SettingTab = () => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { displaySnackbar } = useUtils();

  const handleListItemClick = (event, index) => setSelectedIndex(index);
  const openDialog = () => setOpenResetDialog(true);
  const onClose = () => setOpenResetDialog(false);

  const onResetPassword = async () => {
    try {
      setLoading(true);
      await resetPassword(user.email);
      setLoading(false);
      displaySnackbar(
        intl.formatMessage({
          id: 'setting-tab.reset-password-link-has-been-sent-to-your-email'
        })
      );
      setOpenResetDialog(false);
    } catch (error) {
      setOpenResetDialog(false);
    }
  };

  return (
    <>
      <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32 } }}>
        <ListItemButton
          selected={selectedIndex === 1}
          onClick={(event) => {
            handleListItemClick(event, 1);
            openDialog();
          }}
        >
          <ListItemIcon>
            <RedoOutlined />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage({ id: 'setting-tab.reset-password' })} />
        </ListItemButton>
        <ListItemButton selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0)}>
          <ListItemIcon>
            <QuestionCircleOutlined />
          </ListItemIcon>
          <ListItemText primary={intl.formatMessage({ id: 'setting-tab.support' })} />
        </ListItemButton>
      </List>
      <ConfirmationDialog
        title={intl.formatMessage({
          id: 'setting-tab.reset-password'
        })}
        content={intl.formatMessage({
          id: 'setting-tab.send-email-with-a-reset-password-link'
        })}
        handleConfirm={onResetPassword}
        handleClose={onClose}
        openDialog={openResetDialog}
        loading={loading}
      />
    </>
  );
};

export default SettingTab;
