import PropTypes from 'prop-types';
import React, { createContext, useReducer, useEffect, useCallback, useState } from 'react';

// material-ui
import { CircularProgress } from '@mui/material';

// project import
import useAuth from 'hooks/useAuth';
import { getContactById, getContacts, getContactsByCompanyId } from 'api/ContactsService';
import {
  getCompanyById,
  getCompanies,
  getCompaniesConfig,
  getCompanyBusiness,
  getCompaniesSector,
  getCompanyStatus,
  getCompanyAnalytics,
  getCompanyLabel,
  getCompanyBrandsCompany,
  getCompanyBrands
} from 'api/CompaniesService';

// action - state management
import { LOAD_CONTACTS } from 'store/reducers/actions';
import contactsReducer from 'store/reducers/contacts';

const BASE_URL = process.env.REACT_APP_API_HOST;

const initialState = {
  companiesConfig: null,
  companiesBusiness: null,
  companiesSector: null,
  companiesStatus: null,
  companiesLabel: null,
  companiesBrands: null,
  brands: null
};

// ==============================|| CONTACTS CONTEXT & PROVIDER ||============================== //

const ContactsContext = createContext(initialState);

const ContactsContextProvider = ({ children }) => {
  const { token, user } = useAuth();
  const [state, dispatch] = useReducer(contactsReducer, initialState);
  const [configLoaded, setConfigLoaded] = useState(false);

  useEffect(() => {
    fetchConfigData();
    // eslint-disable-next-line
  }, []);

  const fetchConfigData = useCallback(async () => {
    const [companiesConfig, companiesBusiness, companiesSector, companiesStatus, companiesLabel, companiesBrands, brands] =
      await Promise.all([
        getCompaniesConfig(),
        getCompanyBusiness(),
        getCompaniesSector(),
        getCompanyStatus(),
        getCompanyLabel(),
        getCompanyBrandsCompany(),
        getCompanyBrands()
      ]);

    const payload = {
      type: LOAD_CONTACTS,
      payload: {
        companiesConfig: companiesConfig.data,
        companiesBusiness: companiesBusiness.data,
        companiesSector: companiesSector.data,
        companiesStatus: companiesStatus.data,
        companiesLabel: companiesLabel.data,
        companiesBrands: companiesBrands.data,
        brands: brands.data
      }
    };
    dispatch(payload);
    setConfigLoaded(true);
  }, []);

  const calculatePercentage = (companies) => {
    if (!state.companiesConfig) return;

    for (const company of companies) {
      const mandatoryFields = [];

      for (const [key, value] of Object.entries(state.companiesConfig)) {
        if (value === 1) mandatoryFields.push(key);
      }

      let mandatoryFieldsFilled = 0;

      mandatoryFields.forEach((field) => {
        if (company[field]) {
          mandatoryFieldsFilled += 1;
        }
      });

      const percentage = (mandatoryFieldsFilled / mandatoryFields.length) * 100;
      company.percentage = Math.trunc(percentage);
    }
    return companies;
  };

  const loadAvatar = (item, entity) => {
    if (item.avatar) {
      const endpoint = `/v1/truslog-park/${entity}/${item.id}/getFile`;
      return `${BASE_URL}${endpoint}?fileName=${item.avatar}&low=1&token=${token}`;
    }
  };

  const loadAnalytics = async (id, startDate, endDate) => {
    const analytics = (await getCompanyAnalytics(id, startDate, endDate)).data;
    return analytics;
  };

  const loadCompany = async (id) => {
    const company = (await getCompanyById(id)).data;
    company.avatar = loadAvatar(company, 'companies');
    calculatePercentage([company], state.companiesConfig);
    return company;
  };

  const loadCompanies = async () => {
    if (user && user.role == 'autobuy') return;
    const companies = (await getCompanies()).data;
    calculatePercentage(companies, state.companiesConfig);
    return companies;
  };

  const loadCompaniesFilter = async (filters) => {
    const companies = (await getCompanies(filters)).data;
    return companies;
  };

  const loadCompanyContacts = async (id) => {
    const contacts = (await getContactsByCompanyId(id)).data;
    contacts.forEach((contact) => (contact.avatar = loadAvatar(contact, 'contacts')));

    return contacts;
  };

  const loadContact = async (id) => {
    const contact = (await getContactById(id)).data;
    contact.avatar = loadAvatar(contact, 'contacts');
    return contact;
  };

  const loadContactsFilter = async (filters) => {
    if (!user) return [];
    const contacts = (await getContacts(filters)).data;
    return contacts;
  };

  const loadContacts = async () => {
    if (!user) return [];
    const newContacts = (await getContacts()).data;
    return newContacts;
  };

  return configLoaded ? (
    <ContactsContext.Provider
      value={{
        ...state,
        loadAvatar,
        loadCompany,
        loadCompanies,
        loadContact,
        loadContacts,
        loadCompanyContacts,
        loadAnalytics,
        loadCompaniesFilter,
        loadContactsFilter
      }}
    >
      {children}
    </ContactsContext.Provider>
  ) : (
    <CircularProgress sx={{ alignSelf: 'center', marginTop: 20 }} size={100} color="primary" />
  );
};

ContactsContextProvider.propTypes = {
  children: PropTypes.node
};

export { ContactsContext, ContactsContextProvider };
