import { apiGet, apiPost, apiDelete, apiPut } from './api';

export const getCompanyById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/companies/${id}`, config);
};

export const getCompanies = (filters) => {
  const config = {
    responseType: 'json'
  };

  const query = filters
    ? encodeURI(
        Object.entries(filters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      )
    : '';

  return apiGet(`/v1/trustlog-park/companies?${query}`, config);
};

export const getCompaniesConfig = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/config', config);
};

export const getCompanyBusiness = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/business', config);
};

export const getCompaniesSector = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/sector', config);
};

export const getCompanyStatus = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/status', config);
};

export const getCompanyLabel = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/label', config);
};

export const getCompanyBrandsCompany = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/brands-company', config);
};

export const getCompanyBrands = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/companies/brands', config);
};

export const getCompanyFile = async (id, fileName) => {
  const url = `/v1/trustlog-park/companies/${id}/getFile?fileName=${fileName}`;
  const config = {
    responseType: 'arraybuffer'
  };
  return apiGet(url, config);
};

export const getCompanyTypes = () => {
  const config = { responseType: 'json' };
  return apiGet('/v1/trustlog-park/companies/types', config);
};

export const getCompanyAnalytics = async (id, startDate, endDate) => {
  const url = `/v1/trustlog-park/companies/${id}/analytics?startDate=${startDate}&endDate=${endDate}`;
  const config = { responseType: 'json' };
  return apiGet(url, config);
};

export const postCompanies = (data) => {
  data.forEach((obj) => {
    obj['brands'] = typeof obj.brands === 'string' ? obj.brands.split(',') : Array.isArray(obj.brands) ? obj.brands : [];
  });
  const config = {
    responseType: 'json'
  };
  return apiPost(`/v1/trustlog-park/companies/`, data, config);
};

export const importCompanies = (data) => {
  data.forEach((obj) => {
    obj['brands'] = typeof obj.brands === 'string' ? obj.brands.split(',') : Array.isArray(obj.brands) ? obj.brands : [];
  });
  const config = {
    responseType: 'json'
  };
  return apiPost(`/v1/trustlog-park/companies/import`, data, config);
};

export const syncOdooCompanies = (data) => {
  const config = {
    responseType: 'json'
  };
  return apiPost(`/v1/trustlog-park/odoo-companies/sync`, data, config);
};

export const updateOdooCompanies = (data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/odoo-companies/sync`, data, config);
};

export const getOdooCompanies = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/odoo-companies`, config);
};

export const sendFile = async (id, fileName, file, typeFile = null) => {
  const url = `/v1/trustlog-park/companies/${id}/sendFile`;
  const metadata = JSON.stringify({ fileName, typeFile });
  const blobMetadata = new Blob([metadata], {
    type: 'application/json'
  });

  const formData = new FormData();

  formData.append('metadata', blobMetadata, 'metadata');
  formData.append('file', file);
  const config = {
    responseType: 'json'
  };

  return apiPost(url, formData, config);
};

export const putCompanyById = (id, data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/companies/${id}`, data, config);
};

export const deleteCompanyById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiDelete(`/v1/trustlog-park/companies/${id}`, config);
};

export const getAllCompanyFiles = (id) => {
  const config = {};
  return apiGet(`/v1/trustlog-park/companies/${id}/all-files`, config);
};
