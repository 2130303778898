import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages
const ServiceTable = Loadable(lazy(() => import('pages/services/serviceTable')));
const UserRolesTable = Loadable(lazy(() => import('pages/config/userRolesTable')));

// ==============================|| APPS ROUTING ||============================== //

const ConfigRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'config',
          children: [
            {
              path: 'services',
              element: <ServiceTable />
            },
            {
              path: 'users',
              element: <UserRolesTable />
            }
          ]
        }
      ]
    }
  ]
};

export default ConfigRoutes;
