// third-party
import { useIntl } from 'react-intl';

function useBreadcrubsConfig() {
  const intl = useIntl();

  const breadcrubsConfig = {
    details: {
      value: 'details',
      label: intl.formatMessage({ id: 'use-breadcrubs.details' })
    },
    contacts: {
      value: 'contacts',
      label: intl.formatMessage({ id: 'use-breadcrubs.contacts' })
    }
  };

  return {
    breadcrubsConfig
  };
}
export { useBreadcrubsConfig };
