import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useReducer, useState } from 'react';

// third-party
import axios from 'axios';
import { auth } from 'auth';
import { useIntl } from 'react-intl';
import { onAuthStateChanged, signInWithEmailAndPassword, signOut, getIdTokenResult } from 'firebase/auth';

// action - state management
import { LOGIN, LOGOUT, CLEAN } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import useUtils from 'hooks/useUtils';
import Loader from 'components/Loader';

// const
const REFRESH_MINUTES_MIN = 5;
const BASE_URL = process.env.REACT_APP_API_HOST;
const AxiosInstance = axios.create({ baseURL: BASE_URL });

const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [isAxiosSet, setIsAxiosSet] = useState(false);
  const [token, setToken] = useState('');
  const { displaySnackbar } = useUtils();

  const getLoginPayload = (user, role, defaultPath) => ({
    user: {
      id: user.uid,
      email: user.email,
      name: user.displayName,
      defaultPath,
      role
    }
  });

  // eslint-disable-next-line no-unused-vars
  const getRole = (claims) => 'admin';
  // TO FIX
  // claims.admin
  //   ? 'admin'
  //   : claims.manager
  //   ? 'manager'
  //   : claims.vendor
  //   ? 'vendor'
  //   : claims.finance
  //   ? 'finance'
  //   : claims.autobuy
  //   ? 'autobuy'
  //   : claims.public
  //   ? 'public'
  //   : 'user';

  const refreshToken = useCallback(async () => {
    const currentUser = auth.currentUser;
    const expirationDateMs = currentUser.stsTokenManager.expirationTime;
    const dateNowMs = Date.now();
    const leftTime = expirationDateMs - dateNowMs;
    const minutes = Math.floor(leftTime / (1000 * 60)) % 60;

    if (minutes < REFRESH_MINUTES_MIN) {
      console.info("Token has expired, so let's refresh it", minutes < REFRESH_MINUTES_MIN);
      const tokenResult = await getIdTokenResult(currentUser, true);
      setToken(tokenResult.token);
      return tokenResult.token;
    }

    return token;
  }, [token]);

  // INTERCEPTOR
  useEffect(() => {
    if (!state.isInitialized) return;

    const onResponse = (response) => response;

    const onError = async (error) => {
      console.error('Error caught in AuthInterceptor', error);
      switch (error.response?.status) {
        case 400:
          {
            const message = intl.formatMessage({
              id: 'firebase-context.message-oups-something-went-wrong-request-may-be-invalid'
            });
            displaySnackbar(message, 'error');
          }
          break;
        case 401:
          logout();
          break;
        // 403 ??
        case 500:
          {
            const message = intl.formatMessage({
              id: 'firebase-context.message-oups-something-went-wrong-unexpected-internal-error-occurred'
            });
            displaySnackbar(message, 'error');
          }
          break;
      }
      return Promise.reject(error);
    };

    const onRequest = async (request) => {
      if (state.user) {
        const token = await refreshToken();
        request.headers['auth-token'] = token;
      }
      return request;
    };

    const responseInterceptor = AxiosInstance.interceptors.response.use(onResponse, onError);
    const requestInterceptor = AxiosInstance.interceptors.request.use(onRequest);
    setIsAxiosSet(true);

    return () => {
      AxiosInstance.interceptors.response.eject(responseInterceptor);
      AxiosInstance.interceptors.request.eject(requestInterceptor);
    };
  }, [state, displaySnackbar, intl, refreshToken]);

  // LOGIN
  useEffect(
    () =>
      onAuthStateChanged(auth, (user) => {
        console.log('🚀 ~ onAuthStateChanged ~ user:', user);
        if (user) {
          user.getIdTokenResult(true).then(function (idTokenResult) {
            console.log('🚀 ~ idTokenResult:', idTokenResult);
            setToken(idTokenResult.token);
            const role = getRole(idTokenResult.claims);
            console.log('🚀 ~ role:', role);
            let defaultPath = '';
            switch (role) {
              case 'autobuy':
                defaultPath = '/scripts';
                break;
              case 'public':
                defaultPath = '/events/seminar';
                break;
              case 'user':
                defaultPath = '/commandes';
                break;
              default:
                defaultPath = '/commandes';
                break;
            }
            const payload = getLoginPayload(user, role, defaultPath);
            console.log('🚀 ~ payload:', payload);
            dispatch({ type: LOGIN, payload });
          });
        } else {
          dispatch({ type: LOGOUT });
          dispatch({ type: CLEAN });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const login = (email, password) => {
    console.log('🚀 ~ login ~ email:', email);
    return signInWithEmailAndPassword(auth, email, password);
  };
  const logout = () => {
    signOut(auth);
    dispatch({ type: LOGOUT });
    dispatch({ type: CLEAN });
  };

  if (!isAxiosSet || !state.isInitialized) {
    return <Loader />;
  }

  return <FirebaseContext.Provider value={{ ...state, login, logout, token }}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.propTypes = {
  children: PropTypes.node
};

export default FirebaseContext;
export { AxiosInstance };
