import { apiPut, apiGet, apiPost, apiDelete } from './api';

export const getAllUsers = () => {
  const config = {
    responseType: 'json'
  };
  return apiGet('/v1/trustlog-park/users/', config);
};

export const postUser = (data) => {
  const config = {
    responseType: 'json'
  };
  return apiPost('/v1/trustlog-park/users/', data, config);
};

export const resetPassword = (email) => {
  const config = {
    responseType: 'json'
  };
  return apiGet(`/v1/trustlog-park/users/resetPassword?email=${email}`, config);
};

export const putUserById = (id, data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/users/${id}`, data, config);
};

export const putUserConfig = (data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/users/config`, data, config);
};

export const deleteUserById = (id) => {
  const config = {
    responseType: 'json'
  };
  return apiDelete(`/v1/trustlog-park/users/${id}`, config);
};

export const putUserRoleById = (id, data) => {
  const config = {
    responseType: 'json'
  };
  return apiPut(`/v1/trustlog-park/users/${id}/role`, data, config);
};
