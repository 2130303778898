import { AxiosInstance } from 'contexts/FirebaseContext';

export const apiGet = (url, config = {}) => {
  config.withCredentials = true;
  return AxiosInstance.get(url, config);
};

export const apiPost = (url, data = {}, config = {}) => {
  config.withCredentials = true;

  return AxiosInstance.post(url, data, config);
};

export const apiPut = (url, data = {}, config = {}) => {
  config.withCredentials = true;
  return AxiosInstance.put(url, data, config);
};

export const apiDelete = (url, config = {}) => {
  config.withCredentials = true;
  return AxiosInstance.delete(url, config);
};
