import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Collapse,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Typography
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// project import
import NavItem from './NavItem';
import Transitions from 'components/@extended/Transitions';

// assets
import { BorderOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';

// mini-menu - wrapper
const PopperStyled = styled(Popper)(({ theme }) => ({
  overflow: 'visible',
  zIndex: 1202,
  minWidth: 180,
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 38,
    left: -5,
    width: 10,
    height: 10,
    backgroundColor: theme.palette.background.paper,
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 120,
    borderLeft: `1px solid ${theme.palette.grey.A800}`,
    borderBottom: `1px solid ${theme.palette.grey.A800}`
  }
}));

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

const NavCollapse = ({ menu, level, menuSelected, setMenuSelected }) => {
  const theme = useTheme();
  const params = useParams();

  const menuState = useSelector((state) => state.menu);
  const { drawerOpen } = menuState;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { pathname } = useLocation();

  const isItemSelected = (menuItem) => {
    if (menuSelected === menuItem.id) return true;
    if (!menuItem.children) return false;

    let selected = false;

    for (const children of menuItem.children) {
      selected = isItemSelected(children);
      if (selected) {
        break;
      }
    }

    return selected;
  };

  const isMenuSelected = useCallback(
    (menu, formattedPathname) => {
      let selected = false;

      if (menu.url === formattedPathname) return true;
      if (!menu.children) return false;

      for (const children of menu.children) {
        selected = isMenuSelected(children, formattedPathname);
        if (selected) {
          setMenuSelected(children.id);
          break;
        }
      }

      return selected;
    },
    [setMenuSelected]
  );

  const handleClick = (event) => {
    setAnchorEl(null);
    if (drawerOpen) {
      setOpen(!open);

      if (isItemSelected(menu)) {
        setMenuSelected(menu.id);
      }
    } else {
      setAnchorEl(event?.currentTarget);
    }
  };

  const handleClose = () => setAnchorEl(null);

  const formatPathname = useCallback((pathname) => pathname.substring(0, pathname.indexOf(`/${params.id}`)), [params]);

  useEffect(() => {
    let formattedPathname = pathname;
    if (params.id) {
      formattedPathname = formatPathname(pathname);
    }
    const selected = isMenuSelected(menu, formattedPathname);
    if (selected) {
      setOpen(true);
    } else {
      if (formattedPathname === '/') {
        setMenuSelected();
      }
    }
  }, [isMenuSelected, menu, setMenuSelected, params, pathname, formatPathname]);

  const openMini = Boolean(anchorEl);

  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} menuSelected={menuSelected} setMenuSelected={setMenuSelected} />;
      case 'item':
        return <NavItem menuSelected={menuSelected} key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            <FormattedMessage id="nav-collapse.fix-collapse-or-item" />
          </Typography>
        );
    }
  });

  const borderIcon = level === 1 ? <BorderOutlined style={{ fontSize: '1rem' }} /> : false;
  const Icon = menu.icon;
  const menuIcon = menu.icon ? <Icon style={{ fontSize: drawerOpen ? '1rem' : '1.25rem' }} /> : borderIcon;
  const textColor = theme.palette.mode === 'dark' ? 'grey.400' : 'text.primary';
  const iconSelectedColor = theme.palette.mode === 'dark' && drawerOpen ? theme.palette.text.primary : theme.palette.primary.main;

  return (
    <>
      <ListItemButton
        disableRipple
        selected={isItemSelected(menu)}
        {...(!drawerOpen && { onMouseEnter: handleClick, onMouseLeave: handleClose })}
        onClick={handleClick}
        sx={{
          pl: drawerOpen ? `${level * 28}px` : 1.5,
          py: !drawerOpen && level === 1 ? 1.25 : 1,
          ...(drawerOpen && {
            '&:hover': {
              bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'primary.lighter'
            },
            '&.Mui-selected': {
              bgcolor: 'transparent',
              color: iconSelectedColor,
              '&:hover': { color: iconSelectedColor, bgcolor: theme.palette.mode === 'dark' ? 'divider' : 'transparent' }
            }
          }),
          ...(!drawerOpen && {
            '&:hover': {
              bgcolor: 'transparent'
            },
            '&.Mui-selected': {
              '&:hover': {
                bgcolor: 'transparent'
              },
              bgcolor: 'transparent'
            }
          })
        }}
      >
        {menuIcon && (
          <ListItemIcon
            sx={{
              minWidth: 28,
              color: isItemSelected(menu) ? 'primary.main' : textColor,
              ...(!drawerOpen && {
                borderRadius: 1.5,
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                '&:hover': {
                  bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter'
                }
              }),
              ...(!drawerOpen &&
                isItemSelected(menu) && {
                  bgcolor: theme.palette.mode === 'dark' ? 'primary.900' : 'primary.lighter',
                  '&:hover': {
                    bgcolor: theme.palette.mode === 'dark' ? 'primary.darker' : 'primary.lighter'
                  }
                })
            }}
          >
            {menuIcon}
          </ListItemIcon>
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) && (
          <ListItemText
            primary={
              <Typography variant="h6" color={isItemSelected(menu) ? 'primary' : textColor}>
                {menu.title}
              </Typography>
            }
            secondary={
              menu.caption && (
                <Typography variant="caption" color="secondary">
                  {menu.caption}
                </Typography>
              )
            }
          />
        )}
        {(drawerOpen || (!drawerOpen && level !== 1)) &&
          (openMini || open ? (
            <UpOutlined style={{ fontSize: '0.625rem', marginLeft: 1, color: theme.palette.primary.main }} />
          ) : (
            <DownOutlined style={{ fontSize: '0.625rem', marginLeft: 1 }} />
          ))}

        {!drawerOpen && (
          <PopperStyled
            open={openMini}
            anchorEl={anchorEl}
            placement="right-start"
            style={{
              zIndex: 2001
            }}
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [-12, 1]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions in={openMini} {...TransitionProps}>
                <Paper
                  sx={{
                    overflow: 'hidden',
                    mt: 1.5,
                    boxShadow: theme.customShadows.z1,
                    backgroundImage: 'none',
                    border: `1px solid ${theme.palette.divider}`
                  }}
                >
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{navCollapse}</Box>
                  </ClickAwayListener>
                </Paper>
              </Transitions>
            )}
          </PopperStyled>
        )}
      </ListItemButton>
      {drawerOpen && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List sx={{ p: 0 }}>{navCollapse}</List>
        </Collapse>
      )}
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
  menuSelected: PropTypes.string,
  setMenuSelected: PropTypes.func
};

export default NavCollapse;
