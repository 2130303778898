// project import
import config from './config';
import contacts from './contacts';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [contacts, config]
};

export default menuItems;
