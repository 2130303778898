import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

// material-ui
import { Box, Typography } from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// project import
import NavGroup from './NavGroup';
import menuItems from 'menu-items';
import useAuth from 'hooks/useAuth';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = ({ menuSelected, setMenuSelected }) => {
  const { user } = useAuth();

  const filterAllowedSubMenus = (menuItems, userRole) => {
    const applicationMenu = menuItems.filter((menuItem) => menuItem.id == 'applications_parent');
    const contactsMenu = menuItems.filter((menuItem) => menuItem.id == 'contacts_parent');
    const eventsMenu = menuItems.filter((menuItem) => menuItem.id == 'events_parent');
    const carsMenu = menuItems.filter((menuItem) => menuItem.id == 'cars_parent');

    const autobuyMenu = applicationMenu.map((applicationMenuItem) => {
      const updatedChildren = applicationMenuItem.children.map((applicationChild) => {
        const updatedApplicationChild = applicationChild.children.filter((menuItem) => menuItem.id == 'scripts');
        return { ...applicationChild, children: updatedApplicationChild };
      });
      return { ...applicationMenuItem, children: updatedChildren };
    });

    return userRole == 'autobuy'
      ? autobuyMenu
      : userRole == 'vendor'
      ? [...applicationMenu, ...contactsMenu, ...carsMenu, ...eventsMenu]
      : userRole == 'manager'
      ? [...applicationMenu, ...contactsMenu, ...carsMenu, ...eventsMenu]
      : userRole == 'finance'
      ? [...contactsMenu, ...carsMenu, ...eventsMenu]
      : userRole == 'public'
      ? [...eventsMenu]
      : userRole == 'user'
      ? []
      : menuItems;
  };

  const menu = useSelector((state) => {
    return state.menu;
  });
  const { drawerOpen } = menu;

  const menuItem = {
    items: filterAllowedSubMenus(menuItems.items, user?.role)
  };

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup menuSelected={menuSelected} setMenuSelected={setMenuSelected} key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            <FormattedMessage id="navigation.fix-navigation-group" />
          </Typography>
        );
    }
  });

  return (
    <Box sx={{ color: 'white', justifyContext: 'center', pt: drawerOpen ? 2 : 0, '& > ul:first-of-type': { mt: 0 } }}>{navGroups}</Box>
  );
};

Navigation.propTypes = {
  menuSelected: PropTypes.string,
  setMenuSelected: PropTypes.func
};

export default Navigation;
