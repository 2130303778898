//ACTIONS
import { LOAD_CONTACTS } from 'store/reducers/actions';

// initial state
export const initialState = {
  contacts: null,
  companies: null,
  companiesConfig: null,
  companiesBusiness: null,
  companiesSector: null,
  companiesStatus: null,
  companiesLabel: null,
  companiesBrands: null,
  brands: null
};

// ==============================|| APP REDUCER ||============================== //

const contacts = (state = initialState, action) => {
  if (action.type === LOAD_CONTACTS) {
    const {
      contacts,
      companies,
      companiesConfig,
      companiesBusiness,
      companiesSector,
      companiesStatus,
      companiesLabel,
      companiesBrands,
      brands
    } = action.payload;
    const new_state = {
      ...state,
      contacts,
      companies,
      companiesConfig,
      companiesBusiness,
      companiesSector,
      companiesStatus,
      companiesLabel,
      companiesBrands,
      brands
    };
    return new_state;
  }
  return state;
};

export default contacts;
