import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import Loadable from 'components/Loadable';
import CommonLayout from 'layout/CommonLayout';
import { Navigate } from 'react-router-dom';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <GuestGuard>
          <CommonLayout />
        </GuestGuard>
      ),
      children: [
        {
          path: '',
          element: <Navigate to="login" />
        },
        {
          path: 'login',
          element: <AuthLogin />
        },
        {
          path: 'forgot-password',
          element: <AuthForgotPassword />
        },
        {
          path: '*',
          element: <Navigate to="login" />
        }
      ]
    }
  ]
};

export default LoginRoutes;
