// material-ui
import { Box } from '@mui/material';
import CanoIcon from './../../assets/images/icons/cano.png';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <Box width="118" height="35" viewBox="0 0 118 35" fill="none">
      <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '42px' }} src={CanoIcon}></img>
      <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '42px' }} src={CanoIcon}></img>
      <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '42px' }} src={CanoIcon}></img>
      <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '42px' }} src={CanoIcon}></img>
    </Box>
  );
};

export default LogoIcon;
