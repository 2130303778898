import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Button, Grid, Stack, Typography } from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import error403 from 'assets/images/maintenance/Error403.png';

// project import
import useAuth from 'hooks/useAuth';

// ==============================|| ERROR 403 - MAIN ||============================== //

function Error403() {
  const theme = useTheme();
  const { user } = useAuth();

  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Box sx={{ width: { xs: 500, sm: 550 } }}>
            <img src={error403} alt="mantis" style={{ height: '100%', width: '100%' }} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Stack justifyContent="center" alignItems="center">
            <Typography align="center" variant={matchDownSM ? 'h2' : 'h1'}>
              <FormattedMessage id="403.we-are-sorry" />
            </Typography>
            <Typography color="textSecondary" variant="body2" align="center" sx={{ width: { xs: '73%', sm: '70%' }, mt: 1 }}>
              <FormattedMessage id="403.page-has-restricted-access" />
            </Typography>
            <Button component={Link} to={user.defaultPath} variant="contained" sx={{ textTransform: 'none', mt: 4 }}>
              <FormattedMessage id="403.go-to-homepage" />
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default Error403;
