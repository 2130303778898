// project import
import MainLayout from 'layout/MainLayout';
import ContactsRoutes from './ContactsRoutes';
import AuthGuard from 'utils/route-guard/AuthGuard';

// ==============================|| APPS ROUTING ||============================== //
const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [ContactsRoutes]
    }
  ]
};

export default MainRoutes;
