import { useRoutes } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';
import ConfigRoutes from './ConfigRoutes';
import ErrorRoutes from './ErrorRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { user } = useAuth();
  const serviceRoutes = [LoginRoutes, ErrorRoutes];

  const userRoutesValues = {
    user: [MainRoutes, ConfigRoutes],
    admin: [MainRoutes, ConfigRoutes],
    manager: [MainRoutes, ConfigRoutes],
    vendor: [MainRoutes, ConfigRoutes],
    finance: []
  };
  const currentUserRoutes = user?.role ? userRoutesValues[user?.role] : userRoutesValues['user'];
  const routes = useRoutes(currentUserRoutes ? [...serviceRoutes, ...currentUserRoutes] : serviceRoutes);

  return routes;
}
