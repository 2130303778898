import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import { CircularProgress } from '@mui/material';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }) => {
  const { isLoggedIn, isInitialized } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn && isInitialized) {
      navigate('login');
    }
  }, [isLoggedIn, navigate, isInitialized]);

  return isLoggedIn ? (
    children
  ) : (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={80} />
    </div>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
