import PropTypes from 'prop-types';

// material-ui
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CanoIcon from './../../assets/images/icons/cano.png';
import CanoText from './../../assets/images/icons/canoText.png';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse }) => {
  const theme = useTheme();
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <Box width="118" height="35" viewBox="0 0 118 35" fill="none">
        <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '50px' }} src={CanoIcon}></img>
        <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '50px' }} src={CanoIcon}></img>
        <img alt="Cano Logo" style={{ position: 'fixed', top: 10, left: 10, width: '50px' }} src={CanoIcon}></img>
        <img
          alt="Cano"
          style={{
            position: 'fixed',
            top: 5,
            left: 70,
            width: '180px',
            filter: theme.palette.mode === 'dark' || reverse ? 'invert(1)' : 'invert(-0)'
          }}
          src={CanoText}
        ></img>
      </Box>
    </>
  );
};

LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
