import { FormattedMessage } from 'react-intl';

// assets
import { ReadOutlined, ContactsOutlined, BankOutlined, AuditOutlined, ProfileOutlined } from '@ant-design/icons';

// icons
const icons = {
  ContactsOutlined,
  BankOutlined,
  AuditOutlined,
  ProfileOutlined,
  ReadOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const contacts = {
  id: 'contacts_parent',
  title: '',
  type: 'group',
  children: [
    {
      id: 'contracts',
      title: <FormattedMessage id="CONTRACTS" />,
      icon: icons.ContactsOutlined,
      url: '/contracts',
      type: 'item'
    },
    {
      id: 'demandes',
      title: <FormattedMessage id="menu-items.demandes" />,
      icon: icons.ContactsOutlined,
      url: '/demandes',
      type: 'item'
    },
    {
      id: 'commandes',
      title: <FormattedMessage id="menu-items.commandes" />,
      icon: icons.ContactsOutlined,
      url: '/commandes',
      type: 'item'
    },
    {
      id: 'inventaire',
      title: <FormattedMessage id="menu-items.inventaire" />,
      icon: icons.BankOutlined,
      url: '/inventaire',
      type: 'item'
    },
    {
      id: 'clients',
      title: <FormattedMessage id="menu-items.clients" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/clients'
    },
    {
      id: 'planning',
      title: <FormattedMessage id="menu-items.planning" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/planning'
    },
    {
      id: 'preparation',
      title: <FormattedMessage id="menu-items.preparation" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/preparation'
    },
    {
      id: 'finance',
      title: <FormattedMessage id="menu-items.finance" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/finance'
    },
    {
      id: 'kpi',
      title: <FormattedMessage id="menu-items.kpi" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/kpi'
    },
    {
      id: 'litige',
      title: <FormattedMessage id="menu-items.litiges" />,
      icon: icons.BankOutlined,
      type: 'item',
      url: '/litige'
    }
  ]
};

export default contacts;
