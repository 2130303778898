import PropTypes from 'prop-types';

// project import
import Navigation from './Navigation';
import SimpleBar from 'components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = ({ menuSelected, setMenuSelected }) => {
  return (
    <SimpleBar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Navigation menuSelected={menuSelected} setMenuSelected={setMenuSelected} />
    </SimpleBar>
  );
};

DrawerContent.propTypes = {
  menuSelected: PropTypes.string,
  setMenuSelected: PropTypes.func
};

export default DrawerContent;
