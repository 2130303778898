import PropTypes from 'prop-types';
import { createContext } from 'react';

// project import
import axios from 'axios';
import { openSnackbar } from 'store/reducers/snackbar';
import { useDispatch } from 'react-redux';

// const
const BASE_URL = process.env.REACT_APP_API_HOST;
const AxiosInstance = axios.create({ baseURL: BASE_URL });

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const UtilsContext = createContext(null);

export const UtilsProvider = ({ children }) => {
  const dispatchMessage = useDispatch();

  const displaySnackbar = (message, color) => {
    dispatchMessage(
      openSnackbar({
        open: true,
        message,
        variant: 'alert',
        alert: { color },
        close: true
      })
    );
  };

  return <UtilsContext.Provider value={{ displaySnackbar }}>{children}</UtilsContext.Provider>;
};

UtilsProvider.propTypes = {
  children: PropTypes.node
};

export default UtilsContext;
export { AxiosInstance };
